import axios from "axios";
import { mutate } from "swr";
import { isIOS } from "../utils/systemInfo";

export const HOST = "/wechat/";

const request = axios.create({
  baseURL: HOST,
  withCredentials: true,
  validateStatus: (s) => s >= 200 && s < 300,
});
const toast = (() => {
  const msgTimes = {};

  return (msg) => {
    const now = Date.now();
    const lastTime = msgTimes[msg] ?? 0;
    if (now - lastTime < 3000) {
      return;
    }
    msgTimes[msg] = now;
  };
})();

// 添加请求拦截器
request.interceptors.request.use(
  function (config) {
    // const token = 'eyJhbGciOiJIUzUxMiJ9.eyJuaWNrbmFtZSI6IuWchuiEuOWwkeWls-mDjlx1RDgzRFx1REUwQSIsImFwcF9vcGVuaWQiOiJvNjRZVDZQei14VkFFSzFuWUlOb2o2cDhydUwwIiwiaWQiOjEyLCJhdmF0YXIiOiJodHRwczovL3RoaXJkd3gucWxvZ28uY24vbW1vcGVuL3ZpXzMyL0RZQUlPZ3E4M2VvaWNKc2YwQ3BIUXZpYjV4UFJjaWJZUDd4WWlhZ2gwQ1B0ZEs4UVpzanFWdnYxOE1zVmt0ZVAzMzN1OEpyVGliM2RYOXJNbkRuMDBueUxxWWcvMTMyIiwiZXhwIjoxNjI0NTAyODQ3LCJvcGVuaWQiOiJvVnAzVzVNVThubzFDYy14MWhWTEtlUU9jRFVJIn0._FRvtoaxZc3pXle_Mhu8gam-GSHFrRDLPWwdI20QdkCmy8HGZzWJXc5KNnl0ea7JvfP8pUt0eakAg3LgJ_lBvQ';
    config.headers["Content-Type"] = "application/json";
    config.headers["Platform"] = isIOS ? "IOS" : "Android";

    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (res) => {
    if (res?.data?.code === 401) {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      mutate(`member/`);
    } else if (res?.data?.code === 418) {
      toast(res?.data?.description);
    }
    return res.data;
  },
  function (error) {
    const { response } = error;
    const httpStatus = response?.status;
    const dataCode = response?.data?.code;
    const dataDescription = response?.data?.description;
    if (httpStatus > 200 || dataCode > 200) {
      toast(dataDescription);
    }
    throw error;
  }
);

export const get = (url, data, options = {}) => {
  return request(url, { method: "GET", params: data, ...options });
};
export const post = (url, data, options = {}) => {
  return request(url, { method: "POST", data, ...options });
};
export const put = (url, data, options = {}) => {
  return request(url, { method: "PUT", data, ...options });
};
export const del = (url, data, options = {}) => {
  return request(url, { method: "DELETE", data, ...options });
};
const fetchs = { get, post, put, del };

// mock.init(request)

export default fetchs;
