import asyncComponent from "./asyncImport.js";

const routes = [
  {
    path: "/",
    exact: true,
    component: asyncComponent(() => import(`../pages/guidePage/index.js`)),
  },
];

export default routes;
